// src/pages/admin/ReportDetail.js
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import styles from "./reportDetail.module.css";
import { reportDetail } from "../../../recoil/admin/report";
import useReport from "../../../api/admin/reports/useReport";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";

const ReportDetail = () => {
  const { getReportDetailData, setReportSucced, setReportPass } = useReport();
  const { reportId } = useParams();
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigator = useNavigate();
  useEffect(() => {
    const fetchReports = async () => {
      await getReportDetailData(reportId);
    };

    fetchReports();
  }, [reportId]);

  const report = useRecoilValue(reportDetail);

  const handleSuccess = async () => {
    await setReportSucced(report.reportId);
    setIsModalOpen(true);
  };
  const handlePass = async () => {
    await setReportPass(report.reportId);
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
    navigator("/admin/reports");
  };
  return (
    <div className={styles.container}>
      <div className={styles.mainContent}>
        <h2>신고 상세 정보</h2>
        <p>
          <strong>플로깅 ID:</strong> {report.ploggingId}
        </p>
        <p>
          <strong>사용자:</strong> {report.nickname}
        </p>
        <p>
          <strong>신고일:</strong> {report.reportDate}
        </p>
        <p>
          <strong>상태:</strong>{" "}
          {report.isExecuted ? "처리완료" : "처리 대기중"}
        </p>
        <p>
          <strong>거리:</strong> {report.distance} km
        </p>
        <p>
          <strong>수거 횟수:</strong> {report.pickUpCnt}
        </p>

        <h3>이미지 목록</h3>
        <div className={styles.imageList}>
          {report.ploggingImageList.map((image) => (
            <div
              key={image.ploggingImageId}
              className={styles.imageItem}
              onClick={() => setSelectedImage(image)}
            >
              <img
                src={image.imageUrl}
                alt={`Plogging ${image.label}`}
                className={styles.image}
              />
            </div>
          ))}
        </div>
        <div className={styles.actions}>
          <button onClick={handleSuccess} style={{ backgroundColor: "red" }}>
            벌점 처리
          </button>
          <button onClick={handlePass}>무효 처리</button>
        </div>
      </div>

      {selectedImage && (
        <div className={styles.detailPanel}>
          <h3>선택된 이미지 상세 정보</h3>
          <img
            src={selectedImage.imageUrl}
            alt={`Plogging ${selectedImage.label}`}
            className={styles.detailImage}
          />
          <p>
            <strong>라벨:</strong> {selectedImage.label}
          </p>
          <p>
            <strong>위도:</strong> {selectedImage.latitude}
          </p>
          <p>
            <strong>경도:</strong> {selectedImage.longitude}
          </p>
          <p>
            <strong>생성일:</strong> {selectedImage.createdAt}
          </p>
        </div>
      )}

      {/* Modal for success message */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Success Modal"
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <h2>신고가 제대로 처리되었습니다.</h2>
        <button onClick={handleModalClose}>닫기</button>
      </Modal>
    </div>
  );
};

export default ReportDetail;
