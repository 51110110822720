import { adminInstance, adminLoginInstance } from "../instance";
import { sendRequest } from "../request";

export const sendLoginRequest = async (loginId, password) => {
  const response = await sendRequest(adminLoginInstance, "post", `login`, {
    loginId,
    password,
  });
  return response;
};

export const getReportedUsers = async (
  page = 0,
  size = 10,
  sort = "createdAt"
) => {
  const response = await sendRequest(
    adminInstance,
    "get",
    "reports?page=0&size=10&sort=createdAt"
  );
  return response;
};

export const getReportDetail = async (reportId) => {
  const response = await sendRequest(
    adminInstance,
    "get",
    `reports/${reportId}`
  );
  return response;
};

export const getRemoteStatistics = async () => {
  const response = await sendRequest(adminInstance, "get", "labels");
  return response;
};

export const setRemoteReportSucced = async (reportId) => {
  const response = await sendRequest(
    adminInstance,
    "patch",
    `reports/${reportId}`
  );
  return response;
};

export const setRemoteReportPass = async (reportId) => {
  const response = await sendRequest(
    adminInstance,
    "delete",
    `reports/${reportId}`
  );
  return response;
};
