// src/AppRouter.js
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import IntroPage from "./pages/intro/introPage";
import NotFoundPage from "./pages/error/notFoundPage";
import { Header } from "./components/common/Header/Header";
import LoginPage from "./pages/login/loginPage";
import IntroducePage from "./pages/introduce/introduce";
import Footer from "./components/common/Footer/Footer";
import ReportList from "./pages/admin/reports/reportList";
import Sidebar from "./components/common/Sidebar/sidebar";
import ReportDetail from "./pages/admin/reports/reportDetail";
import StatisticsScreen from "./pages/admin/statistics/statisticsScreen";
const AppRouter = () => (
  <Router>
    <Header />
    <Routes>
      <Route path="/" element={<IntroPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/introduce" element={<IntroducePage />} />
      <Route path="*" element={<NotFoundPage />} />

      <Route
        path="/admin/*"
        element={
          <div style={{ display: "flex", width: "100%" }}>
            <Sidebar />
            <Routes>
              <Route path="/reports" element={<ReportList />} />
              <Route path="/reports/:reportId" element={<ReportDetail />} />
              <Route path="/teams" element={<ReportList />} />
              <Route path="/statistics" element={<StatisticsScreen />} />
            </Routes>
          </div>
        }
      />
    </Routes>
    <Footer />
  </Router>
);

export default AppRouter;
