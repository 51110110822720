import IntroComponent from "../../components/intro/IntroComponent";

function IntroPage() {
  return (
    <>
      <IntroComponent />
    </>
  );
}

export default IntroPage;
