import React, { useEffect } from "react";
import { Line, Bar } from "react-chartjs-2"; // Import Line for line charts
import { useRecoilValue } from "recoil";
import { useStatistics } from "../../../api/admin/statistics/useStatistics";
import {
  labelInfoState,
  monthlyInfoState,
} from "../../../recoil/admin/statistics";

// Import and register necessary components from Chart.js
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement, // Add PointElement for line charts
  LineElement, // Add LineElement for line charts
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StatisticsScreen = () => {
  const monthlyInfo = useRecoilValue(monthlyInfoState);
  const labelInfo = useRecoilValue(labelInfoState);
  const { getStatistics } = useStatistics();

  useEffect(() => {
    const fetchStatistics = async () => {
      await getStatistics();
    };
    fetchStatistics();
  }, []);

  // Sort dates and prepare data for monthly plogging time and trash count
  const sortedDates = monthlyInfo ? Object.keys(monthlyInfo).sort() : [];

  const monthlyData = {
    labels: sortedDates,
    datasets: [
      {
        label: "월간 플로깅 시간 (시간)",
        data: sortedDates.map(
          (date) => monthlyInfo[date][0]?.monthlyPloggingTime / 60 || 0 // Access first element and handle null
        ),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 2,
        fill: false, // Do not fill the area under the line
      },
      {
        label: "월간 쓰레기 수거 횟수",
        data: sortedDates.map(
          (date) => monthlyInfo[date][0]?.monthlyTrashCnt || 0 // Access first element and handle null
        ),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 2,
        fill: false, // Do not fill the area under the line
      },
    ],
  };

  // Prepare data for label information
  const labelData = {
    labels: labelInfo ? Object.keys(labelInfo) : [],
    datasets: [
      {
        label: "쓰레기 종류별 수량",
        data: labelInfo ? Object.values(labelInfo) : [],
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#FF9F40",
          "#4BC0C0",
          "#9966FF",
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
        ],
        borderColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#FF9F40",
          "#4BC0C0",
          "#9966FF",
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div style={{ width: "100%", padding: "20px" }}>
      <h2>월간 데이터</h2>
      <div style={{ width: "100%", height: "300px" }}>
        <Line // Use Line instead of Bar for line charts
          data={monthlyData}
          options={{ responsive: true, maintainAspectRatio: false }}
        />
      </div>

      <h2>라벨링 데이터 정보</h2>
      <div style={{ width: "100%", height: "300px" }}>
        <Bar
          data={labelData}
          options={{ responsive: true, maintainAspectRatio: false }}
        />
      </div>
    </div>
  );
};

export default StatisticsScreen;
