// src/hooks/useReport.js
import { useSetRecoilState } from "recoil";

import {
  getReportDetail,
  getReportedUsers,
  setRemoteReportPass,
  setRemoteReportSucced,
} from "../amdin";
import { reportDetail, reportListState } from "../../../recoil/admin/report";

export const useReport = () => {
  const setReportList = useSetRecoilState(reportListState);
  const setReportDetail = useSetRecoilState(reportDetail);

  const getReports = async () => {
    try {
      const response = await getReportedUsers();
      if (response) {
        setReportList(response.data.data);
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const getReportDetailData = async (reportId) => {
    try {
      const response = await getReportDetail(reportId);
      if (response) {
        setReportDetail(response.data.data);
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const setReportSucced = async (reportId) => {
    try {
      const response = await setRemoteReportSucced(reportId);
      if (response) {
        return response;
      } else {
        throw new Error("Failed to set report as succeeded");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setReportPass = async (reportId) => {
    try {
      const response = await setRemoteReportPass(reportId);
      if (response) {
        return response;
      } else {
        throw new Error("Failed to set report as passed");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    getReports,
    getReportDetailData,
    setReportSucced,
    setReportPass,
  };
};

export default useReport;
