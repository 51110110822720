// src/hooks/useReport.js
import { useSetRecoilState } from "recoil";
import {
  labelInfoState,
  monthlyInfoState,
} from "../../../recoil/admin/statistics";
import { getRemoteStatistics } from "../amdin";

export const useStatistics = () => {
  const setMonthlyInfo = useSetRecoilState(monthlyInfoState);
  const setLabelInfo = useSetRecoilState(labelInfoState);
  const getStatistics = async () => {
    try {
      const response = await getRemoteStatistics();
      if (response) {
        setMonthlyInfo(response.data.data.monthlyInfo);
        setLabelInfo(response.data.data.labelInfo);
        console.log(response.data.data.monthlyInfo);
        console.log(response.data.data.labelInfo);
        console.log("세팅 완료");
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    getStatistics,
  };
};

export default useStatistics;
