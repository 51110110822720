// src/recoil/report-atoms.js
import { atom } from "recoil";

export const reportListState = atom({
  key: "reportListState",
  default: {
    selectPrompt: [],
    pageInfo: {
      currentPage: 0,
      totalPages: 0,
      pageSize: 0,
      totalItems: 0,
      currentItems: 0,
    },
  },
});

export const reportDetail = atom({
  key: "reportDetail",
  default: {
    reportId: 0,
    ploggingId: 0,
    nickname: "",
    reportDate: "",
    isExecuted: false,
    reportsCnt: 0,
    distance: 0,
    pickUpCnt: 0,
    ploggingImage:
      "",
    ploggingImageList: [
      {
        ploggingImageId: 1,
        imageUrl: "",
        createdAt: "",
        latitude: 0.0,
        longitude: 1.234,
        label: "PLASTIC",
      },
      {
        ploggingImageId: 2,
        imageUrl: "",
        createdAt: "",
        latitude: 0.0,
        longitude: 1.234,
        label: "PLASTIC",
      },
    ],
  },
});
