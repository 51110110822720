import axios from "axios";
import { applyInterceptors } from "./interceptor";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const adminLoginInstance = axios.create({
  baseURL: `https://${BASE_URL}/api/v1/auth/admin`, // 프로토콜 추가 및 경로 수정
  proxy: false,
});

const adminInstance = axios.create({
  baseURL: `https://${BASE_URL}/api/v1/admin`, // 프로토콜 추가 및 경로 수정
  proxy: false,
});
//**인터셉터 적용할 instance들은 여기서 다 정한다. */

applyInterceptors(adminInstance);

export { BASE_URL, adminInstance, adminLoginInstance };
