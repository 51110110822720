import React, { useState } from "react";
import styles from "./loginPage.module.css"; // CSS 모듈로 임포트

import logoW from "../../assets/images/earth/earth_model_3.png";
import { sendLoginRequest } from "../../api/admin/amdin";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [loginData, setLoginData] = useState({
    loginId: "",
    password: "",
  });

  const navigator = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const setAuthCookie = (token, callback) => {
    document.cookie = `accessToken=${token}; path=/;`;
    if (callback) callback(); // 쿠키 설정 후 콜백 실행
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const { loginId, password } = loginData;
    const response = await sendLoginRequest(loginId, password);

    if (response.data.data.accessToken) {
      setAuthCookie(response.data.data.accessToken, () => {
        console.log("로그인 성공");
        navigator("/admin");
      });
    }
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <img
          src={logoW}
          width={200}
          height={400}
          alt="로고"
          className={styles.logo}
        />
      </header>
      <main className={styles.mainContent}>
        <h3>로그인 후 서비스를 이용할 수 있습니다람쥐.</h3>
        <form onSubmit={handleLogin} className={styles.loginForm}>
          <input
            type="text"
            name="loginId"
            value={loginData.username}
            onChange={handleInputChange}
            placeholder="아이디를 입력하세요"
            className={styles.input}
          />
          <input
            type="password"
            name="password"
            value={loginData.password}
            onChange={handleInputChange}
            placeholder="비밀번호를 입력하세요"
            className={styles.input}
          />
          <button type="submit" className={styles.loginButton}>
            로그인
          </button>
        </form>
      </main>
    </div>
  );
};

export default LoginPage;
