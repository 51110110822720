// src/recoil/reportAtoms.js
import { atom } from "recoil";

export const monthlyInfoState = atom({
  key: "monthlyInfoState",
  default: {},
});

export const labelInfoState = atom({
  key: "labelInfoState",
  default: {},
});
