// src/components/ReportList.js
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import styles from "./reportList.module.css";
import useReport from "../../../api/admin/reports/useReport";
import { reportListState } from "../../../recoil/admin/report";
import { Link } from "react-router-dom";

const ReportList = () => {
  const { getReports } = useReport();
  const reportData = useRecoilValue(reportListState);
  const [activeTab, setActiveTab] = useState("incomplete");

  useEffect(() => {
    const fetchReports = async () => {
      await getReports();
    };

    fetchReports();
  }, []);

  const filteredReports = reportData.selectPrompt.filter((report) =>
    activeTab === "incomplete" ? !report.isExecuted : report.isExecuted
  );

  return (
    <div className={styles.container}>
      <div className={styles.tabBar}>
        <button
          className={`${styles.tab} ${activeTab === "incomplete" ? styles.active : ""}`}
          onClick={() => setActiveTab("incomplete")}
        >
          미완료
        </button>
        <button
          className={`${styles.tab} ${activeTab === "complete" ? styles.active : ""}`}
          onClick={() => setActiveTab("complete")}
        >
          완료
        </button>
      </div>
      {filteredReports.map((report) => (
        <div
          key={report.reportId}
          className={`${styles.reportCard} ${report.isExecuted ? styles.executed : ""}`}
        >
          <div className={styles.reportContent}>
            <h3 className={styles.title}>신고 #{report.reportId}</h3>
            <Link
              to={`/admin/reports/${report.reportId}`}
              className={styles.noUnderline}
            >
              <div className={styles.reportDetails}>
                <p>플로깅 ID: {report.ploggingId}</p>
                <p>신고된 사용자: {report.nickname}</p>
                <p>신고일: {report.reportDate}</p>
                <p>보고 횟수: {report.reportsCnt}</p>
                <p>거리: {report.distance} km</p>
                <p>수거 횟수: {report.pickUpCnt}</p>
              </div>
              <div className={styles.tagContainer}>
                {/* 신고 횟수에 따른 태그 */}
                {report.reportsCnt >= 5 ? (
                  <span className={styles.urgentTag}>긴급</span>
                ) : report.reportsCnt >= 3 ? (
                  <span className={styles.dangerTag}>위험</span>
                ) : report.reportsCnt >= 2 ? (
                  <span className={styles.warningTag}>주의</span>
                ) : (
                  <span className={styles.safeTag}>최초</span>
                )}

                {/* 처리 상태에 따른 태그 */}
                {report.isExecuted ? (
                  <span className={styles.processedTag}>처리완료</span>
                ) : (
                  <span className={styles.pendingTag}>대기중</span>
                )}

                {/* 거리에 따른 태그 */}
                {report.distance > 5 ? (
                  <span className={styles.safeTag}>장거리</span>
                ) : (
                  <span className={styles.warningTag}>단거리</span>
                )}

                {/* 수거 횟수에 따른 태그 */}
                {report.pickUpCnt > 10 ? (
                  <span className={styles.safeTag}>다중 수거</span>
                ) : (
                  <span className={styles.urgentTag}>소량 수거</span>
                )}
              </div>{" "}
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ReportList;
